import React from "react"

// UI
import SectionThreeCols, { ThreeColCard } from "~components/SectionThreeCols"
import { faPlug, faListTree, faHandshake } from "@fortawesome/pro-thin-svg-icons"

const GetStartedFeaturesList: React.FC = () => (
  <SectionThreeCols
    title="Whether you're offering personal or business loans, payment cards, or BNPL, Canopy has you covered."
    level={3}
    titleTextSize="text-2xl md:text-3xl xl:text-4xl"
    className="mb-16 lg:mb-24 2xl:mb-40"
    narrow
  >
    <ThreeColCard title="Immutable Ledger" icon={faListTree} data-sal="slide-up">
      <p>Track every change to your ledger, run real-time calculations, and explore dynamic, retroactive events.</p>
    </ThreeColCard>
    <ThreeColCard title="Low code integrations" icon={faPlug} data-sal="slide-up" data-sal-delay="100">
      <p>
        Built to support modern payment cards, so you can streamline issuing, payment processing, and servicing in one
        platform.
      </p>
    </ThreeColCard>
    <ThreeColCard title="Personalized Service" icon={faHandshake} data-sal="slide-up" data-sal-delay="200">
      <p>
        Increase customer service productivity and borrower trust with automations that allow your organization to
        deliver personalized assistance.{" "}
      </p>
    </ThreeColCard>
  </SectionThreeCols>
)

export default GetStartedFeaturesList
