import React from "react"

// UI
import Headline from "~ui/Headline"
import SectionCallout from "~components/SectionCallout"

import avatarImg from "~images/use-case/avatar-dash-fi.jpg"

const GetStartedTestimonial: React.FC = () => (
  <>
    <SectionCallout className="mb-16 md:mb-24" narrow>
      <Headline level={3} textSize="text-2xl md:text-3xl xl:text-4xl" className="pb-4 lg:pb-8 text-white">
        Dramatically cut your time to launch&nbsp;
        <br className="hidden xl:block" />
        new lending products.
      </Headline>
      <p className="lg:text-xl text-white leading-tight lg:leading-snug mb-6 md:mb-10">
        &quot;Canopy makes launching credit products fast and easy. If you want to create an innovative product, it’s
        actually quite difficult if you go at it alone. Canopy was critical in converting AdCard from a debit card to a
        charge card.&quot;
      </p>
      <div className="space-x-6">
        <div className="inline-block align-middle overflow-hidden rounded-full w-16 h-16 bg-blue-400">
          <img src={avatarImg} alt="Zack Johnson - FunnelDash" />
        </div>
        <div className="inline-block align-middle text-left text-white">
          <strong>Zach Johnson</strong>
          <br /> Founder, dash.fi
        </div>
      </div>
    </SectionCallout>
  </>
)

export default GetStartedTestimonial
