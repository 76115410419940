import React from "react"

// UI
import SectionMediaFull from "~components/SectionMediaFull"

const GetStartedMedia: React.FC = () => (
  <SectionMediaFull
    title="Modern Lenders Choose Canopy"
    subTitle="Hear from David Anderson of Tandym and Matt Lekawa of NaluPay on why they chose Canopy to support their long-term
    lending vision."
    video={
      <iframe
        src="https://www.youtube.com/embed/YBFaftFy-Ks?rel=0&modestbranding=1"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
        className="w-full"
        allowFullScreen
      />
    }
    className="mb-20 md:mb-40"
    narrow
  />
)

export default GetStartedMedia
