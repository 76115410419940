import React from "react"

import Layout from "~components/layout"
import SEO from "~components/seo"
import GetStartedFeaturesList from "~content/get-started/FeaturesList"
import GetStartedHero from "~content/get-started/Hero"
import GetStartedMedia from "~content/get-started/Media"
import GetStartedTestimonial from "~content/get-started/Testimonial"

const GetStartedPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/get-started"
        description="Make your mark with differentiated Buy Now Pay Later programs for consumers and businesses."
        title="Get Started | Canopy Servicing"
      />
      <GetStartedHero />
      <GetStartedTestimonial />
      <GetStartedFeaturesList />
      <GetStartedMedia />
    </Layout>
  )
}

export default GetStartedPage
