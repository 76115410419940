import React from "react"

// UI
import SectionHero from "~components/SectionHero"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"

const GetStartedHero: React.FC = () => (
  <>
    <SectionHero
      title={
        <>
          Unlock Lending Innovation.
          <br /> <span className="text-blue-500">Build on Canopy.</span>
        </>
      }
      subtitle="Building credit, debit, or next-gen lending products? Canopy's API-first platform allows you to embed financial products, go to market quickly, and service borrowers in a secure, compliant way."
      buttons={false}
      narrow
    />

    <Spirograph
      shape={4}
      width="w-full sm:w-3/4 md:w-3/4 xl:w-3/5"
      position="right-0"
      offsetY="-translate-y-1/2 sm:-translate-y-3/20 md:-translate-y-1/4"
      offsetX="translate-x-1/2 sm:translate-x-1/3 md:translate-x-2/5"
    />
    <Aura
      color="purple"
      size="w-full2x lg:w-full"
      position="left-0"
      offsetY="-translate-y-2/5"
      offsetX="-translate-x-1/2"
      opacity="opacity-20"
    />
    <Aura
      size="w-full2x sm:w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-1/2 sm:-translate-y-1/3"
      offsetX="translate-x-1/2"
      opacity="opacity-50"
    />
    <Aura
      color="purple"
      size="w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-3/20 md:-translate-y-1/20"
      offsetX="translate-x-1/2 md:translate-x-3/5"
      opacity="opacity-30"
    />
  </>
)

export default GetStartedHero
